<template>
  <form-slot-layout
    title="지면 위치"
    guide-id="popover-attachment"
  >
    <guide-popover
      target="popover-attachment"
      title="지면 위치 가이드"
    >
      지면이 포함된 화면 전체를 캡쳐하여 등록하실 지면에 표시해주세요.<br><br>
      해당 캡쳐 이미지는 광고주들이 캠페인을 신청할 때 지면의 형태나 위치에 대한 정보를 제공하기 위해 활용됩니다.<br><br><br>
      이미지 권장 사양 : 최소 1000 * 300 px<br>
      파일 형식 : JPEG, PNG<br>
      파일 크기 : 30MB 이내<br>
    </guide-popover>
    <div class="d-flex flex-column">
      <b-form-input
        v-if="disable || readOnly"
        size="lg"
        disabled
      />
      <b-form-file
        v-else
        v-model="imageFile"
        accept="image/jpeg, image/png"
        size="lg"
        browse-text="불러오기"
        placeholder="가이드를 확인하시어 지면이 포함된 화면을 캡쳐해 업로드해주세요."
      />
      <small
        v-show="!isFileValid"
        class="text-danger mt-1"
      >
        첨부파일을 선택해주세요.
      </small>
      <div
        v-if="disable"
        class="sxa-image-preview"
      />
      <img
        v-else
        ref="imgPrev"
        contain
        class="sxa-image-preview"
      >
    </div>
  </form-slot-layout>
</template>

<script>
import {
  BFormFile, BFormInput,
} from 'bootstrap-vue'
import {
  computed, inject, onMounted, ref, watch,
} from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    BFormInput,
    BFormFile,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      imageSource, imageFile, layout,
    } = inject('currentSlot')
    const isFileValid = inject('isFileValid')
    const imgPrev = ref(null)
    // local file 업로드시 프리뷰 이미지 생성
    const mountFile = () => {
      if (imageFile.value) {
        // FileReader 인스턴스 생성
        const reader = new FileReader()
        // 이미지가 로드가 된 경우
        reader.onload = e => {
          const previewImage = imgPrev.value
          previewImage.src = e.target.result
        }
        // reader가 이미지 읽도록 하기
        reader.readAsDataURL(imageFile.value)
      }
    }
    watch(imageFile, () => mountFile())

    onMounted(() => {
      if (imageSource.value && imgPrev.value) {
        const previewImage = imgPrev.value
        previewImage.src = imageSource.value
      }
    })

    const disable = computed(() => layout.value === 'SEARCH')

    return {
      imgPrev,
      imageFile,
      disable,
      isFileValid,
    }
  },
}
</script>
<style lang="scss">
.sxa-image-preview {
  width: 180px;
  height: 120px;
  background-color: var(--secondary);
  opacity: 0.4;
  margin-top: 15px;
}
</style>
