<template>
  <loading-component
    v-if="loading"
  />
  <error-component
    v-else-if="error"
    :error="error"
  />
  <validation-observer
    v-else
    ref="refForm"
  >
    <app-form-layout>

      <template
        v-if="!isRegister"
        #top
      >
        <slot-status />
      </template>

      <template #left>
        <button-selector-form
          title="버전 구분"
          data-key="currentSlot"
          model-key="device"
          option-key="deviceOptions"
          :read-only="isReadOnly"
        />
        <name-form
          type="지면"
          inject-key="currentSlot"
          :read-only="isReadOnly"
        />
        <button-selector-form
          title="참여 인벤토리"
          data-key="currentSlot"
          model-key="inventory"
          option-key="inventoryOptions"
          guide-id="popover-inventory"
          :read-only="isReadOnly"
        />
        <guide-popover
          target="popover-inventory"
          title="참여 인벤토리 가이드"
        >
          단독 채널 : 광고주가 선택시 단독 채널 내에서만 광고가 송출되며, 과금 방식이나 과금 금액을 직접 설정할 수 있습니다.<br><br>
          네트워크 광고 : 등록하시는 지면이 네트워크 채널에 소속되어 광고주가 선택시 채널에 등록된 다양한 네트워크 광고 지면에 광고가 송출됩니다. 따라서 과금 방식이나 과금 금액을 직접 설정할 수는 없지만 일정 수익은 발생할 수 있습니다.
        </guide-popover>
        <slot-date-selector :read-only="isReadOnly" />
        <slot-charge-selector :read-only="isReadOnly" />
      </template>

      <template #right>
        <button-selector-form
          title="광고 형식"
          data-key="currentSlot"
          model-key="layout"
          option-key="layoutOptions"
          guide-id="popover-type"
          :read-only="isReadOnly"
        />
        <guide-popover
          target="popover-type"
          title="광고 형식 가이드"
        >
          해당 지면에 운영되는 광고의 형식이 이미지 광고, 동영상 광고, 검색 광고인지 선택해주세요.
        </guide-popover>
        <slot-size-form :read-only="isReadOnly" />
        <slot-attachment :read-only="isReadOnly" />
        <slot-max-search-length :read-only="isReadOnly" />
        <slot-description-form :read-only="isReadOnly" />
      </template>

      <template
        v-if="isRegister && !isReadOnly"
        #submit-button
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2 mt-2"
          block
          @click="onCreate"
        >
          지면 생성하기
        </b-button>
      </template>

      <template
        v-else-if="!isReadOnly"
        #submit-button
      >
        <b-button
          v-if="isAccepted"
          v-b-modal.modal-alert-update
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2 mt-2"
          block
          :disabled="!hasChange"
        >
          지면 수정하기
        </b-button>
        <b-button
          v-else
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2 mt-2"
          block
          :disabled="!hasChange"
          @click="onUpdate"
        >
          지면 수정하기
        </b-button>
        <b-button
          v-if="isRejected"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2 mt-2"
          block
          @click="onRequireRevision"
        >
          재심사 요청하기
        </b-button>
        <b-button
          v-if="!isAccepted"
          v-b-modal.modal-alert-delete
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="mb-2 mt-2"
          block
        >
          지면 삭제하기
        </b-button>
        <b-card-text class="text-center mt-2">
          <b-link
            style="text-decoration: underline"
            @click="showCode"
          >
            코드 보기
          </b-link>
        </b-card-text>

      </template>
    </app-form-layout>

    <!-- modal update alert -->
    <b-modal
      id="modal-alert-update"
      title="현재 지면을 수정하시겠습니까?"
      ok-title="수정하기"
      cancel-title="취소"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      hide-header-close
      centered
      @ok="onUpdate"
    >
      <b-card-text>
        승인된 지면을 수정하실 경우<br>
        프로젝트X 담당자의 재심사와 승인이 필요합니다.<br>
        그래도 진행하시겠습니까?
      </b-card-text>
    </b-modal>

    <!-- modal delete alert -->
    <b-modal
      id="modal-alert-delete"
      title="현재 지면을 삭제하시겠습니까?"
      ok-title="삭제"
      cancel-title="취소"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      hide-header-close
      centered
      @ok="onDelete"
    >
      <b-card-text>삭제한 데이터는 복원할 수 없습니다.</b-card-text>
    </b-modal>

  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import {
  BButton, BLink, BCardText,
  VBModal, BModal,
} from 'bootstrap-vue'
import useSlot from '@/views/utils/slot/useSlot'
import { computed, onMounted } from '@vue/composition-api'
import { channelRouteNames } from '@/router/routes/channel'
import useSlotTask from '@/views/utils/task/useSlotTask'
import { canWriteSlot } from '@/libs/acl/protection'
import { useRouter } from '@core/utils/utils'
import AppFormLayout from '@/views/layouts/AppFormLayout.vue'
import NameForm from '@/views/components/NameForm.vue'
import ButtonSelectorForm from '@/views/components/ButtonSelectorForm.vue'
import SlotStatus from '../../../components/slot/SlotStatus.vue'
import SlotChargeSelector from '../../../components/slot/SlotChargeSelector.vue'
import SlotDateSelector from '../../../components/slot/SlotDateSelector.vue'
import SlotSizeForm from '../../../components/slot/SlotSizeForm.vue'
import SlotAttachment from '../../../components/slot/SlotAttachment.vue'
import SlotDescriptionForm from '../../../components/slot/SlotDescriptionForm.vue'
import SlotMaxSearchLength from '../../../components/slot/SlotMaxSearchLength.vue'

export default {
  components: {
    ValidationObserver,
    NameForm,
    AppFormLayout,
    BButton,
    BLink,
    BCardText,
    BModal,
    SlotStatus,
    ButtonSelectorForm,
    SlotChargeSelector,
    SlotDateSelector,
    SlotSizeForm,
    SlotAttachment,
    SlotDescriptionForm,
    SlotMaxSearchLength,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  props: {
    isRegister: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      loading,
      error,
      fetchSlot,
      createSlot,
      updateSlot,
      requestReview,
      deleteSlot,
    } = useSlotTask()

    const {
      refForm,
      validate,
      makeSlotData,
      setCurrentSlot,
      hasChange,
      currentSlot,
    } = useSlot()

    const { route, router } = useRouter()

    const { reviewStatus, status } = currentSlot
    const isAccepted = computed(() => reviewStatus.value === 'ACCEPTED')
    const isRejected = computed(() => reviewStatus.value === 'REJECTED')
    const isReadOnly = computed(() => status.value === 'CLOSED' || !canWriteSlot())

    const onUpdate = async () => {
      if (validate()) {
        reviewStatus.value = 'PENDING'
        const slot = makeSlotData()
        const newSlot = await updateSlot(slot)
        setCurrentSlot(newSlot)
      }
    }

    const onDelete = async () => {
      const slot = makeSlotData()
      await deleteSlot(slot)
      router.go(-1)
    }

    const onRequireRevision = async () => {
      if (validate()) {
        const slot = makeSlotData()
        const newSlot = await requestReview(slot)
        setCurrentSlot(newSlot)
      }
    }

    const onCreate = async () => {
      if (validate()) {
        const slot = makeSlotData()
        const newSlot = await createSlot(slot)
        router.push({
          name: channelRouteNames.slotViewCode,
          params: {
            channelId: newSlot.channelAccountId,
            slotId: newSlot.id,
            newSlot: true,
          },
        })
      }
    }

    const showCode = () => {
      const { channelId, slotId } = route.value.params
      router.push({
        name: channelRouteNames.slotViewCode,
        params: {
          channelId,
          slotId,
        },
      })
    }

    onMounted(async () => {
      if (!props.isRegister) {
        const { slotId } = route.value.params
        const slot = await fetchSlot(slotId)
        setCurrentSlot(slot)
      }
    })

    return {
      loading,
      error,
      refForm,
      showCode,
      onUpdate,
      onCreate,
      onRequireRevision,
      onDelete,
      isRejected,
      isAccepted,
      isReadOnly,
      hasChange,
    }
  },
}
</script>
<style lang="scss">
.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
</style>
